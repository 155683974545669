import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import MainModel from "../../../components/Models/main-model";
import { closeAddVoucherModel } from "../../../modules/admin-modules/models/Actions";
import { addVoucherRequest } from "../../../modules/students-modules/videos/Actions";

const AddVoucher = ({ video_id }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.model.isOpen21);
  const videoId = useSelector((state) => state.model.payload);
  const [voucherData, setVoucherData] = useState();
  const handelSendVoucher = () => {
    let formData = new FormData();
    formData.append("voucher", voucherData);
    formData.append("video_id", videoId);

    dispatch(
      addVoucherRequest({
        formData: {
          formData: formData,
        },
        action: () => {
          dispatch(closeAddVoucherModel());
        },
      })
    );
  };

  const header = <Box></Box>;

  const closeFunc = () => {
    dispatch(closeAddVoucherModel());
  };
  return (
    <MainModel
      isLoading={false}
      header={header}
      closeFunc={closeFunc}
      openVar={isOpen}
      buttonTitle={""}
      buttonFunc={() => {}}
    >
      <Flex
        alignItems="center"
        pb="60px"
        pt="20px"
        px="30px"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Flex
          flexDirection={"column"}
          justifyContent={"space-between"}
          w={{ base: "100%", md: "600px" }}
        >
          <Text fontSize={"26px"} color="main_1">
            voucher code
          </Text>
          <Box w="100%">
            <Input
              style={{
                width: "100%",
                padding: "20px",
                objectFit: "contain",
                borderRadius: "10px",
                boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                border: "solid 1px #010038",
                backgroundColor: "#fff",
              }}
              onChange={(e) => setVoucherData(e?.target?.value)}
            />
          </Box>
        </Flex>
        <Button
          onClick={handelSendVoucher}
          mt="40px"
          type="submit"
          w="30%"
          p="22px"
          alignSelf={"center"}
          mx="10px"
          bg="main_1"
          color="white"
        >
          Save
        </Button>
      </Flex>
    </MainModel>
  );
};

export default AddVoucher;
