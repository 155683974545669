import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  RiCheckLine,
  RiDeleteBin6Line,
  RiDeleteBinLine,
  RiFileEditLine,
} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import CTable from "../../../components/Table/table";
import {
  openAddVideoFielsModel,
  openCreateVideos,
} from "../../../modules/admin-modules/models/Actions";
import {
  acceptRequestWithoutVoucherRequest,
  deleteRequestRequest,
  editRequestRequest,
} from "../../../modules/admin-modules/video-requests/Actions";
import { RiQrScan2Line } from "react-icons/ri";
import { openUserQRCodeModel } from "../../../modules/admin-modules/models/Actions";
const VideosRequestTable = ({ setAdd, setSelectedData }) => {
  const videos = useSelector((state) => state.videoRequests.requests);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.videoRequests.isLoading);
  const [offset, setPage] = useState(0);
  const [size, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState({
    id: "",
  });
  const viewData = (data) => {
    setSelectedData(data);
    setSelected(data);
  };

  const Data = videos?.map((data) => {
    return {
      ...data,
      id: data?.id,
      video_name: data?.video?.title,
      student_name: data?.student?.name,
      chapter_id: data?.chapter?.id,

      WithVoucher: (
        <IconButton
          variant="outline"
          border="0"
          color="green.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiCheckLine />}
          onClick={async () => {
            let formData = new FormData();
            formData.append("_method", "PUT");
            formData.append("video_id", data?.video?.id);

            await dispatch(
              editRequestRequest({
                formData: formData,
                id: data?.id,
              })
            );
          }}
        />
      ),
      code: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiQrScan2Line />}
          onClick={() => dispatch(openUserQRCodeModel())}
        />
      ),
      WithoutVoucher: (
        <IconButton
          variant="outline"
          border="0"
          color="green.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiCheckLine />}
          onClick={async () => {
            await dispatch(acceptRequestWithoutVoucherRequest(data?.id));
          }}
        />
      ),
      delete: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiDeleteBinLine color="red" />}
          onClick={() => {
            dispatch(deleteRequestRequest(data?.id));
          }}
        />
      ),
    };
  });
  const columns = [
    {
      Header: " عنوان الفيديو",
      accessor: "video_name",
    },

    {
      Header: "  اسم الطالب",
      accessor: "student_name",
    },
    {
      Header: "Qr Code",
      accessor: "code",
    },
    {
      Header: "قبول",
      accessor: "WithVoucher",
    },
    {
      Header: "حذف",
      accessor: "delete",
    },
  ];

  const actions = (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<RiFileEditLine />}
        size="sm"
        fontSize="20px"
        variant="outline"
        border="none"
      />
      <MenuList>
        <MenuItem
          onClick={() => {
            setAdd(false);
            dispatch(openCreateVideos());
          }}
        >
          تعديل
        </MenuItem>
        <MenuItem
          onClick={() =>
            // dispatch(deleteClubRequest(selected?.id))
            {
              //console.log('test')
            }
          }
        >
          حذف
        </MenuItem>
      </MenuList>
    </Menu>
  );
  return (
    <CTable
      selectedData={viewData}
      Columns={columns}
      Data={Data}
      path={"openModel"}
      Actions={actions}
      Title={``}
      subTitle=""
      btnTitle=""
      placeHolder=""
      noSearchBar={true}
      noFilter={true}
      footerBtnTitle
      filterList
      addButton
      addButtonTitle=""
      setPage={setPage}
      setPerPage={setPerPage}
      currentpage={pageNumber}
      setPageNumber={setPageNumber}
      noSecondSearchBar={true}
      isLoading={isLoading}
      perPage={size}
      totalPage={Math.ceil(videos?.length / 10)}
    />
  );
};

export default VideosRequestTable;
