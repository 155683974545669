import { actions } from "./Reducer";

export const openCreateStudent = () => ({
  type: actions.OPEN_MODEL_1,
});

export const closeCreateStudent = () => ({
  type: actions.CLOSE_MODEL_1,
});

export const openCreateGroup = () => ({
  type: actions.OPEN_MODEL_2,
});

export const closeCreateGroup = () => ({
  type: actions.CLOSE_MODEL_2,
});

export const openCreateLsson = () => ({
  type: actions.OPEN_MODEL_3,
});

export const closeCreateLesson = () => ({
  type: actions.CLOSE_MODEL_3,
});

export const openCreateStreaming = () => ({
  type: actions.OPEN_MODEL_4,
});

export const closeCreateStreaming = () => ({
  type: actions.CLOSE_MODEL_4,
});

export const openCreateVideos = () => ({
  type: actions.OPEN_MODEL_5,
});

export const closeCreateVideos = () => ({
  type: actions.CLOSE_MODEL_5,
});

export const openCreateEvent = () => ({
  type: actions.OPEN_MODEL_6,
});

export const closeCreateEvent = () => ({
  type: actions.CLOSE_MODEL_6,
});
export const openCreateTeacher = () => ({
  type: actions.OPEN_MODEL_7,
});

export const closeCreateTeacher = () => ({
  type: actions.CLOSE_MODEL_7,
});

export const openCreateTeacherAssistant = () => ({
  type: actions.OPEN_MODEL_8,
});

export const closeCreateTeacherAssistant = () => ({
  type: actions.CLOSE_MODEL_8,
});

export const openShowStudentDataModel = () => ({
  type: actions.OPEN_MODEL_9,
});

export const closeShowStudentDataModel = () => ({
  type: actions.CLOSE_MODEL_9,
});

export const openAddVideoFielsModel = () => ({
  type: actions.OPEN_MODEL_10,
});

export const closeAddVideoFielsModel = () => ({
  type: actions.CLOSE_MODEL_10,
});

export const openCreateChapterModel = () => ({
  type: actions.OPEN_MODEL_11,
});

export const closeCreateChapterModel = () => ({
  type: actions.CLOSE_MODEL_11,
});

export const openCreateLectureModel = () => ({
  type: actions.OPEN_MODEL_12,
});

export const closeCreateLectureModel = () => ({
  type: actions.CLOSE_MODEL_12,
});

export const openUserDataModel = () => ({
  type: actions.OPEN_MODEL_13,
});

export const closeUserDataModel = () => ({
  type: actions.CLOSE_MODEL_13,
});

export const openUserQRCodeModel = () => ({
  type: actions.OPEN_MODEL_14,
});

export const closeUserQRCodeModel = () => ({
  type: actions.CLOSE_MODEL_14,
});

export const openCreateExamDataModel = () => ({
  type: actions.OPEN_MODEL_15,
});

export const closeCreateExamDataModel = () => ({
  type: actions.CLOSE_MODEL_15,
});

export const openCreateQuestionModel = () => ({
  type: actions.OPEN_MODEL_16,
});

export const closeCreateQuestionModel = () => ({
  type: actions.CLOSE_MODEL_16,
});

export const openExamAnswersLoading = () => ({
  type: actions.OPEN_MODEL_17,
});

export const closeExamAnswersLoading = () => ({
  type: actions.CLOSE_MODEL_17,
});

export const openStudentReportModel = () => ({
  type: actions.OPEN_MODEL_18,
});

export const closeStudentReportModel = () => ({
  type: actions.CLOSE_MODEL_18,
});

export const openCreatePaymentModel = () => ({
  type: actions.OPEN_MODEL_19,
});

export const closeCreatePaymentModel = () => ({
  type: actions.CLOSE_MODEL_19,
});

export const openVoucherModel = () => ({
  type: actions.OPEN_MODEL_20,
});

export const closeVoucherModel = () => ({
  type: actions.CLOSE_MODEL_20,
});

export const openAddVoucherModel = (id) => ({
  type: actions.OPEN_MODEL_21,
  payload: id,
});

export const closeAddVoucherModel = () => ({
  type: actions.CLOSE_MODEL_21,
});

export const openAddHomeworkModel = () => ({
  type: actions.OPEN_MODEL_22,
});

export const closeAddHomeworkModel = () => ({
  type: actions.CLOSE_MODEL_22,
});

export const openSolveHomeworkModel = () => ({
  type: actions.OPEN_MODEL_23,
});

export const closeSolveHomeworkModel = () => ({
  type: actions.CLOSE_MODEL_23,
});

export const openTagModel = () => ({
  type: actions.OPEN_MODEL_24,
});

export const closeTagModel = () => ({
  type: actions.CLOSE_MODEL_24,
});

export const openPlaylistModel = () => ({
  type: actions.OPEN_MODEL_25,
});

export const closePlaylistModel = () => ({
  type: actions.CLOSE_MODEL_25,
});

export const openQuestionsListModel = () => ({
  type: actions.OPEN_MODEL_26,
});

export const closeQuestionsListModel = () => ({
  type: actions.CLOSE_MODEL_26,
});

export const openAdminHomeworkCommentModel = () => ({
  type: actions.OPEN_MODEL_27,
});

export const closeAdminHomeworkCommentModel = () => ({
  type: actions.CLOSE_MODEL_27,
});

export const openDeleteHomeworkModel = () => ({
  type: actions.OPEN_MODEL_28,
});

export const closeDeleteHomeworkModel = () => ({
  type: actions.CLOSE_MODEL_28,
});
export const openAddSectionkModel = () => ({
  type: actions.OPEN_MODEL_29,
});

export const closeAddSectionkModel = () => ({
  type: actions.CLOSE_MODEL_29,
});
export const openExamHintkModel = () => ({
  type: actions.OPEN_MODEL_30,
});

export const closeExamHintkModel = () => ({
  type: actions.CLOSE_MODEL_30,
});

export const openRefuseHomeworkModel = () => ({
  type: actions.OPEN_MODEL_32,
});

export const closeRefuseHomeworkModel = () => ({
  type: actions.CLOSE_MODEL_32,
});
