import {
    Box,
    Button,
    Flex,
    IconButton,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    Text,
    VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RiFileEditLine, RiForbid2Line, RiQrScan2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import CTable from "../../../components/Table/table";
import Select from "react-select";
import { attendanceReportRequest } from "../../../modules/admin-modules/reports/Actions";
import { getAllLecturesRequest } from "../../../modules/admin-modules/lectures/Actions";
import Layout from "../../../components/dashboard/Layout";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { getManualExamsRequest } from "../../../modules/admin-modules/exams/Actions";
import { getManualExamsMarksRequest } from "../../../modules/admin-modules/exams/Actions";
import { setManualExamMarkRequest } from "../../../modules/admin-modules/exams/Actions";

const AttendanceReport = ({ setAdd, setSelectedData, searchTerm }) => {
    const dispatch = useDispatch();
    const [lecId, setLecId] = useState("");
    const [date, setDate] = useState(dayjs(new Date()).format("YYYY-MM-DD"));
    const [examId, setExamId] = useState("");

    const [marks, setMarks] = useState([]); // Add state for marks
    const [scores, setScores] = useState({}); // Replace single score state with scores object

    // Get marks from Redux store
    const examMarks = useSelector((state) => state.exams.marks);

    // Watch for marks changes
    useEffect(() => {
        if (examMarks && examMarks.length > 0) {
            setMarks(examMarks);
        }
    }, [examMarks]);
    useEffect(() => {
        if (lecId) dispatch(attendanceReportRequest(lecId, date));
    }, [lecId, date]);

    useEffect(() => {
        dispatch(getAllLecturesRequest());
        dispatch(getManualExamsRequest());
    }, []);

    const getMarks = (id) => {
        setExamId(id);
        dispatch(getManualExamsMarksRequest(id));
    };

    const sendGrade = (studentId, score) => {
        dispatch(
            setManualExamMarkRequest({
                student_id: studentId,
                exam_id: examId,
                score: score,
            })
        );
    };

    const lectures = useSelector((state) => state.lectures.lectures);
    const exams = useSelector((state) => state.exams.exams);

    const lecOptions = lectures?.map((data) => {
        return { value: data.id, label: data.title };
    });

    const attendancesReport = useSelector(
        (state) => state.reports.attendancesReport
    );

    const [offset, setPage] = useState(0);

    const [size, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [selected, setSelected] = useState({
        id: "",
    });
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            width: "100%",
            background: "#f5f5f5",
            color: "#690000",
            borderRadius: "16px",
        }),
        option: (styles) => ({ ...styles, color: "#690000" }),
    };

    const viewData = (data) => {
        setSelectedData(data);
        setSelected(data);
    };
    const headers = [
        { label: "name", key: "name" },
        { label: "group_name", key: "group_name" },
        { label: "phone", key: "phone" },
        { label: "grade", key: "grade" },
        { label: "send_grade", key: "send_grade" },
    ];
    const Data = attendancesReport?.map((data, index) => {
        const studentMarks = marks.find((mark) => mark.student_id === data?.id);
        return {
            ...data,
            index: `#${index + 1}`,
            id: data?.id,
            name: data?.name,
            group_name: data?.group?.name,
            phone: data?.phone,
            grade: (
            <Input
                placeholder="Enter Grade"
                _placeholder={{ color: "black" }}
                defaultValue={studentMarks?.score || ""}
                onChange={(e) => {
                setScores(prev => ({
                    ...prev,
                    [data.id]: e.target.value
                }));
                }}
                style={{
                backgroundColor:
                    !studentMarks && !scores[data.id] ? "#FF7F7F" : "#90EE90",
                }}
            />
            ),
            send_grade: (
            <Button
                onClick={(e) => {
                sendGrade(data?.id, scores[data.id]);
                e.target.innerText = "✔";
                }}
            >
                Send Grade
            </Button>
            ),
        };
    });
    const columns = [
        {
            Header: "رقم الطالب",
            accessor: "index",
        },
        {
            Header: "اسم الطالب",
            accessor: "name",
        },
        {
            Header: " المجموعة",
            accessor: "group_name",
        },
        {
            Header: " Qr Code",
            accessor: "code",
        },
        {
            Header: " رقم  الطالب",
            accessor: "phone",
        },
        {
            Header: "الدرجة",
            accessor: "grade",
        },
        {
            Header: "ارسال الدرجة",
            accessor: "send_grade",
        }
    ];

    return (
        <Layout>
            <div style={{ minHeight: "400px" }}>
                <Flex
                    w="100%"
                    my="30px"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Text fontSize="24px" fontWeight="600" color="main_1">
                        تقرير الحضور
                    </Text>

                    <Flex w="60%" justifyContent="space-between" alignItems="center">
                        <Select
                            options={lecOptions}
                            isMulti={false}
                            onChange={(data) => {
                                setLecId(data?.value);
                            }}
                            styles={colourStyles}
                            placeholder="Select lecture"
                        />
                        <Input
                            placeholder="Select Date and Time"
                            size="md"
                            type="datetime-local"
                            w="50%"
                            mx="8px"
                            onChange={(e) => {
                                const formatedDate = dayjs(e.target.value).format("YYYY-MM-DD");
                                setDate(formatedDate);
                            }}
                        />
                        {Data?.length !== 0 && (
                            <Select
                                options={exams.map((data) => {
                                    return { value: data.id, label: data.name };
                                })}
                                isMulti={false}
                                onChange={(data) => {
                                    getMarks(data?.value);
                                }}
                                styles={colourStyles}
                                placeholder="Select Exam"
                            />
                        )}
                        <Box
                            mx="10px"
                            border="1px solid gray"
                            borderRadius="8px"
                            padding="10px"
                        >
                            <CSVLink
                                headers={headers}
                                data={Data?.length != 0 ? Data : []}
                                filename={"attendence-report.csv"}
                                className="btn btn-primary"
                                target="_blank"
                            >
                                Download
                            </CSVLink>
                        </Box>
                        <LogoutMenu />
                    </Flex>
                </Flex>

                <CTable
                    selectedData={viewData}
                    Columns={columns}
                    Data={Data}
                    path={"openModel"}
                    Actions
                    Title={``}
                    subTitle=""
                    btnTitle=""
                    placeHolder=""
                    noSearchBar={true}
                    noFilter={true}
                    footerBtnTitle
                    filterList
                    addButton
                    addButtonTitle=""
                    setPage={setPage}
                    setPerPage={setPerPage}
                    currentpage={pageNumber}
                    setPageNumber={setPageNumber}
                    noSecondSearchBar={true}
                    isLoading={false}
                    perPage={size}
                    totalPage={Math.ceil(10 / 50)}
                />
            </div>
        </Layout>
    );
};

export default AttendanceReport;
