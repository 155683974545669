export default {
  //get all EXAMs
  GET_EXAMS_REQUEST: "GET_EXAMS_REQUEST",
  GET_EXAMS_SUCCESS: "GET_EXAMS_SUCCESS",
  GET_EXAMS_FAIL: "GET_EXAMS_FAIL",

  GET_ALL_EXAM_QUESTIONS_REQUEST: "GET_ALL_EXAM_QUESTIONS_REQUEST",
  GET_ALL_EXAM_QUESTIONS_SUCCESS: "GET_ALL_EXAM_QUESTIONS_SUCCESS",
  GET_ALL_EXAM_QUESTIONS_FAIL: "GET_ALL_EXAM_QUESTIONS_FAIL",

  CREATE_EXAM_QUESTIONS_REQUEST: "CREATE_EXAM_QUESTIONS_REQUEST",
  CREATE_EXAM_QUESTIONS_SUCCESS: "CREATE_EXAM_QUESTIONS_SUCCESS",
  CREATE_EXAM_QUESTIONS_FAIL: "CREATE_EXAM_QUESTIONS_FAIL",

  GET_EXAMS_SUPER_ADMIN_REQUEST: "GET_EXAMS_SUPER_ADMIN_REQUEST",
  GET_EXAMS_SUPER_ADMIN_SUCCESS: "GET_EXAMS_SUPER_ADMIN_SUCCESS",
  GET_EXAMS_SUPER_ADMIN_FAIL: "GET_EXAMS_SUPER_ADMIN_FAIL",

  GET_ADMIN_STUDENT_EXAMS_REQUEST: "GET_ADMIN_STUDENT_EXAMS_REQUEST",
  GET_ADMIN_STUDENT_EXAMS_SUCCESS: "GET_ADMIN_STUDENT_EXAMS_SUCCESS",
  GET_ADMIN_STUDENT_EXAMS_FAIL: "GET_ADMIN_STUDENT_EXAMS_FAIL",

  //edit EXAMs
  EDIT_EXAM_REQUEST: "EDIT_EXAM_REQUEST",
  EDIT_EXAM_SUCCESS: "EDIT_EXAM_SUCCESS",
  EDIT_EXAM_FAIL: "EDIT_EXAM_FAIL",

  //create EXAM
  CREATE_EXAM_REQUEST: "CREATE_EXAM_REQUEST",
  CREATE_EXAM_SUCCESS: "CREATE_EXAM_SUCCESS",
  CREATE_EXAM_FAIL: "CREATE_EXAM_FAIL",

  DELETE_EXAM_REQUEST: "DELETE_EXAM_REQUEST",
  DELETE_EXAM_SUCCESS: "DELETE_EXAM_SUCCESS",
  DELETE_EXAM_FAIL: "DELETE_EXAM_FAIL",

  PUBLISH_EXAM_REQUEST: "PUBLISH_EXAM_REQUEST",
  PUBLISH_EXAM_SUCCESS: "PUBLISH_EXAM_SUCCESS",
  PUBLISH_EXAM_FAIL: "PUBLISH_EXAM_FAIL",

  GET_EXAM_REQUEST: "GET_EXAM_REQUEST",
  GET_EXAM_SUCCESS: "GET_EXAM_SUCCESS",
  GET_EXAM_FAIL: "GET_EXAM_FAIL",

  ENABLE_EXAM_REQUEST: "ENABLE_EXAM_REQUEST",
  ENABLE_EXAM_SUCCESS: "ENABLE_EXAM_SUCCESS",
  ENABLE_EXAM_FAIL: "ENABLE_EXAM_FAIL",

  RESTRICT_EXAM_REQUEST: "RESTRICT_EXAM_REQUEST",
  RESTRICT_EXAM_SUCCESS: "RESTRICT_EXAM_SUCCESS",
  RESTRICT_EXAM_FAIL: "RESTRICT_EXAM_FAIL",
  //loading
  ADD_EXAMS_LOADING: "ADD_EXAMS_LOADING",
  PUBLISH_EXAM_LOADING: "PUBLISH_EXAM_LOADING",
  RESTRICT_EXAM_LOADING: "RESTRICT_EXAM_LOADING",
  ENABLE_EXAM_LOADING: "ENABLE_EXAM_LOADING",
  EDIT_EXAM_QUESTION_REQUEST: "EDIT_EXAM_QUESTION_REQUEST",
  EDIT_EXAM_QUESTION_SUCCESS: "EDIT_EXAM_QUESTION_SUCCESS",
  EDIT_EXAM_QUESTION_FAIL: "EDIT_EXAM_QUESTION_FAIL",

  ADD_BULK_CORRECTION_REQUEST: "ADD_BULK_CORRECTION_REQUEST",
  ADD_BULK_CORRECTION_SUCCESS: "ADD_BULK_CORRECTION_SUCCESS",
  ADD_BULK_CORRECTION_FAIL: "ADD_BULK_CORRECTION_FAIL",

  GET_MANUAL_EXAMS_REQUEST: "GET_MANUAL_EXAMS_REQUEST",
  GET_MANUAL_EXAMS_SUCCESS: "GET_MANUAL_EXAMS_SUCCESS",
  GET_MANUAL_EXAMS_FAIL: "GET_MANUAL_EXAMS_FAIL",

  GET_MANUAL_EXAMS_MARKS_REQUEST: "GET_MANUAL_EXAMS_MARKS_REQUEST",
  GET_MANUAL_EXAMS_MARKS_SUCCESS: "GET_MANUAL_EXAMS_MARKS_SUCCESS",
  GET_MANUAL_EXAMS_MARKS_FAIL: "GET_MANUAL_EXAMS_MARKS_FAIL",

  SET_MANUAL_EXAM_MARK_REQUEST: "SET_MANUAL_EXAM_MARK_REQUEST",
  SET_MANUAL_EXAM_MARK_SUCCESS: "SET_MANUAL_EXAM_MARK_SUCCESS",
  SET_MANUAL_EXAM_MARK_FAIL: "SET_MANUAL_EXAM_MARK_FAIL",
};
